import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { LinearProgress } from "@material-ui/core";
import useGeneralStyle from "../assets/css/general";

function DefaultLoader() {
  return (
    <Box textAlign={"center"} marginTop="50px">
      <RotatingLines
        visible={true}
        height="96"
        width="96"
        color="grey"
        strokeWidth="5"
        strokeColor="#fbc068"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Box>
  );
}

export function LinearProgressWithLabel(props) {
  const classes = useGeneralStyle();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          classes={{
            root: classes.progressBarRoot,
            barColorPrimary: classes.barColorPrimary,
          }}
          variant={props.value == 1 ? "indeterminate" : "determinate"}
          {...props}
        />
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box> */}
    </Box>
  );
}

export default DefaultLoader;
