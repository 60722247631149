import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  fetchProductOffers,
  fetchProductsList,
  fetchSlots,
  fetchUser,
  fetchUserList,
  getServiceBills,
  setCategory,
  setCollectedOrders,
  setConfigurations,
  setCustomerById,
  setFormStructure,
  setFormValidations,
  setGeneralApiData,
  setInventoryData,
  setLoader,
  setOrderData,
  setOrdersData,
  setPOSData,
  setProductOffersData,
  setProductsData,
  setProductsList,
  setPurchaseData,
  setReducerData,
  setReturnedOrders,
  setServiceBills,
  setSlotsData,
  setSuppliersData,
  setUser,
  setUserList,
  setUsersList,
} from "../actions";
import { fetchData, makeRequest } from "../api";
import {
  FETCH_CITIES_DATA,
  FETCH_COUNTRY_DATA,
  FETCH_STATE_DATA,
  GENERAL_API_CALL,
  TYPES,
  UPDATE_BUSINESS_CONFIGURATION,
} from "../constants";
import { selectGeneralReducer } from "../selectors";
import { convertBackendErrorResponse } from "../../utils/utility";
import { indexDB } from "../../utils/indexDb";

function* generalApiCall({ payload }) {
  try {
    const data = yield call(fetchData, payload);
    yield put(setGeneralApiData({ data: data, key: payload.key }));

    if (payload.hasOwnProperty("callback")) payload.callback();
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}
function* updateSlot({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    yield call(makeRequest, {
      method: "PUT",
      endpoint: `/partner/updateSlot/${payload.id}`,
      apiBody: payload,
    });

    yield put(
      fetchSlots({
        business_id,
      })
    );
  } catch (er) {
    console.log("UPDATE SLOT API ERROR:", er);
  }
}

function* getCitiesData({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/common/getCities",
      apiBody: payload,
    });

    yield put(setReducerData({ data: data.data.data, property: "cities" }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getStateData({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/common/getStates",
      apiBody: payload,
    });

    yield put(setReducerData({ data: data.data.data, property: "states" }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getCountriesData({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/common/getCountry",
      apiBody: payload | {},
    });

    yield put(setReducerData({ data: data.data.data, property: "countries" }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* updateConfigurationsApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    yield call(makeRequest, {
      method: "PUT",
      endpoint: `/partner/updateConfiguration/${business_id}`,
      apiBody: payload,
    });

    yield put(setConfigurations(payload));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* updateBusinessConfigurationsData({ payload }) {
  console.log("payload:", payload);
  try {
    yield call(makeRequest, {
      method: "POST",
      endpoint: "/partner/updateBusinessConfiguration",
      apiBody: payload,
    });

    yield put(fetchUser());
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getUserData({ payload }) {
  try {
    const { data } = yield call(fetchData, {
      method: "GET",
      endpoint: "/partner/user",
      apiBody: payload || {},
    });

    yield put(setUser(data));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getFormStructureApiCall({ payload }) {
  try {
    const data = yield call(fetchData, {
      method: "POST",
      endpoint: "/partner/getFormStructure",
      apiBody: payload,
    });

    yield put(setFormStructure(data));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* addCustomerApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    yield call(makeRequest, {
      method: "POST",
      endpoint: "/partner/addCustomer",
      apiBody: payload,
    });

    yield put(
      fetchUserList({
        page: 1,
        page_size: 10,
        search_string: null,
        user_type: "service_customers",
        business_id: business_id,
      })
    );

    yield put(setReducerData({ data: false, property: "modal_status" }));
  } catch (er) {
    yield put(setFormValidations(convertBackendErrorResponse(er.errors)));
  }
}

function* updateCustomerApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    const data = yield call(makeRequest, {
      method: "POST",
      endpoint: "/partner/updateCustomer",
      apiBody: payload,
    });

    console.log("UPDATE CUSOTMER:", data);

    yield put(
      fetchUserList({
        page: 1,
        page_size: 10,
        search_string: null,
        user_type: "service_customers",
        business_id: business_id,
      })
    );

    yield put(setReducerData({ data: false, property: "modal_status" }));
  } catch (er) {
    yield put(setFormValidations(convertBackendErrorResponse(er.errors)));
  }
}

function* fetchOrdersApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/orders?business_id=${business_id}&${new URLSearchParams(
        payload
      )}`,
      apiBody: {},
    });

    yield put(setOrdersData(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchSuppliersApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/fetchSuppliers?business_id=${business_id}&${new URLSearchParams(
        payload
      )}`,
      apiBody: {},
    });

    yield put(setSuppliersData(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchInventoryApiCall({ payload }) {
  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/inventory?${new URLSearchParams(payload)}`,
      apiBody: {},
    });

    yield put(setInventoryData(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchUserListApiCall({ payload }) {
  try {
    const data = yield call(makeRequest, {
      method: "POST",
      endpoint: "/partner/getUsers",
      apiBody: payload,
    });

    console.log("USERSS HERE:", data.data);
    yield put(setUserList({ data: data.data, user_type: payload.user_type }));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getServiceBillsApiCall({ payload }) {
  let { pageNumber, pageSize, paymentMode, search, filter } = payload;

  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    const data = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/service-bills?page_size=${pageSize}&payment_status=${paymentMode}&type=partner&page=${pageNumber}&search_string=${search}&pagination_required=true&business_id=${business_id}`,
      apiBody: {},
    });

    console.log("SERVICE BILLS LIST:", data);
    yield put(setServiceBills(data.data));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getCustomerById({ payload }) {
  let { id, user_type, paymentMode } = payload;

  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    const data = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/userData/${id}?business_id=${business_id}&type=${user_type}`,
      apiBody: {},
    });

    yield put(setCustomerById(data.data));
    yield put(
      getServiceBills({
        pageSize: 10,
        pageNumber: 1,
        search: data.data.mobile_number,
        paymentMode,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* updateServiceBillTransactionApiCall({ payload }) {
  let { paymentMode, search } = payload;

  let {
    userData: { id, business_id },
  } = yield select(selectGeneralReducer);

  try {
    const data = yield call(makeRequest, {
      method: "POST",
      endpoint: `/partner/updateServiceBillTransaction`,
      apiBody: {
        partner_id: id,
        business_id: business_id,
      },
    });

    yield put(
      getServiceBills({
        pageSize: 10,
        pageNumber: 1,
        search,
        paymentMode,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getOnlineOrderById({ payload }) {
  let { order_id, callback } = payload;

  try {
    const data = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/online-sales/${order_id}`,
      apiBody: {},
    });
    console.log("GET ORDER BY ID:", data);

    yield put(setOrderData(data));

    if (callback) callback();
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getOrderByIdApiCall({ payload }) {
  let { order_id, callback } = payload;

  try {
    const data = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/sales/${order_id}`,
      apiBody: {},
    });
    console.log("GET ORDER BY ID:", data);

    yield put(setOrderData(data));

    callback();
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* createSlotApiCall({ payload }) {
  let { business_id } = payload;

  try {
    const { data } = yield call(makeRequest, {
      method: "POST",
      endpoint: `/partner/createSlot`,
      apiBody: payload,
    });

    yield put(
      fetchSlots({
        business_id,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchSlotsApiCall({ payload }) {
  let { business_id } = payload;

  try {
    const { data } = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/fetchSlots?business_id=${business_id}`,
      apiBody: {},
    });

    yield put(setSlotsData(data));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* updatePOSOrderBillStatus({ payload }) {
  let { id, status, callback } = payload;

  try {
    const data = yield call(makeRequest, {
      method: "PUT",
      endpoint: `/partner/updatePOSPaymentStatus/${id}`,
      apiBody: {
        status,
      },
    });

    callback();
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* deletePOSBillApiCall({ payload }) {
  console.log("payload:", payload);
  let { id, callback } = payload;

  try {
    const data = yield call(makeRequest, {
      method: "DELETE",
      endpoint: `/partner/removePOSBill/${id}`,
      apiBody: {},
    });

    callback();
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchPOSDataApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/listSales?business_id=${business_id}&${new URLSearchParams(
        payload
      )}`,
      apiBody: {},
    });

    yield put(setPOSData(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchProductOffersDataApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/getProductOffers?business_id=${business_id}&${new URLSearchParams(
        payload
      )}`,
      apiBody: {},
    });

    yield put(setProductOffersData(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchPurchaseDataApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/listPurchase?business_id=${business_id}&${new URLSearchParams(
        payload
      )}`,
      apiBody: {},
    });

    yield put(setPurchaseData(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchProductsProgressivelyListApiCall({ payload }) {
  let { business_id, pagination } = payload;
  try {
    let page = 1;
    let hasMore = true;

    while (hasMore) {
      const {
        data: { data },
      } = yield call(makeRequest, {
        method: "POST",
        endpoint: `/partner/getAllProductsData`,
        apiBody: {
          business_id,
          pagination,
          page,
        },
      });

      if (data.length > 0) {
        indexDB.writeData("products", data);
        page += 1;
      } else {
        hasMore = false;
      }
    }
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchProductsListApiCall({ payload }) {
  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/getProducts?business_id=${business_id}&${new URLSearchParams(
        payload
      )}`,
      apiBody: {},
    });

    yield put(setProductsList(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* importProductsApiCall({ payload }) {
  let { callback, errorHandler } = payload;
  const formData = new FormData();
  formData.append("file", payload.filepath);

  try {
    const {
      data: { data },
    } = yield call(makeRequest, {
      method: "POST",
      endpoint: `/partner/import`,
      apiBody: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    callback(data);
  } catch (er) {
    errorHandler(er);
  }
}

function* fetchProductsApiCall({ payload }) {
  let { business_id, pagination } = payload;

  try {
    const {
      data: { data },
    } = yield call(makeRequest, {
      method: "POST",
      endpoint: `/partner/getAllProductsData`,
      apiBody: {
        business_id,
        pagination,
      },
    });

    yield put(setProductsData(data));
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* addPOSOrderApiCall({ payload }) {
  let { callback } = payload;

  yield put(
    setLoader({
      key: "actionLoader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "POST",
      endpoint: `/partner/addPOSOrder`,
      apiBody: { ...payload },
    });

    yield put(
      setLoader({
        key: "actionLoader",
        data: false,
      })
    );

    callback(response);
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchUsersList({ payload }) {
  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "POST",
      endpoint: `/partner/getUsers`,
      // endpoint: `/partner/getUsers?${new URLSearchParams(payload)}`,
      apiBody: {
        ...payload,
      },
    });

    yield put(setUsersList(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchReturnedOrders({ payload }) {
  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/listReturns?${new URLSearchParams(payload)}`,
      apiBody: {},
    });

    yield put(setReturnedOrders(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* fetchCollectedOrders({ payload }) {
  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/partner/getCollectedOrders?${new URLSearchParams(payload)}`,
      apiBody: {},
    });

    yield put(setCollectedOrders(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* getCategoryListApiCall({ payload }) {
  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    const response = yield call(makeRequest, {
      method: "GET",
      endpoint: `/common/category?${new URLSearchParams({
        ...payload,
        business_id,
        pagination_required: true,
      })}`,
      apiBody: {},
    });

    yield put(setCategory(response.data));

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );
  } catch (er) {
    console.log("GENERAL API ERROR:", er);
  }
}

function* updateProductOfferApiCall({ payload }) {
  let { callback } = payload;

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    yield call(makeRequest, {
      method: "PUT",
      endpoint: `/partner/updateProductOffer/${payload.id}`,
      apiBody: {
        ...payload,
        business_id,
      },
    });

    yield put(fetchProductOffers());

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );

    callback();
  } catch (er) {
    console.log("Error: ", er);
  }
}

function* addProductOfferApiCall({ payload }) {
  let { callback } = payload;

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  try {
    yield call(makeRequest, {
      method: "POST",
      endpoint: `/partner/createProductOffer`,
      apiBody: {
        ...payload,
        business_id,
      },
    });

    yield put(fetchProductOffers());

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );

    callback();
  } catch (er) {
    console.log("Error: ", er);
  }
}

function* updateProductDataApiCall({ payload }) {
  let { callback, product_id } = payload;

  let {
    userData: { business_id },
  } = yield select(selectGeneralReducer);

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    yield call(makeRequest, {
      method: "PUT",
      endpoint: `/partner/updateProduct/${product_id}`,
      apiBody: {
        ...payload,
        business_id
      },
    });

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );

    callback();
  } catch (er) {
    console.log("Error: ", er);
  }
}

function* updateWebsiteMetaApiCall({ payload }) {
  let { callback } = payload;

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    yield call(makeRequest, {
      method: "POST",
      endpoint: `/partner/updateMeta`,
      apiBody: payload,
    });

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );

    callback();
  } catch (er) {
    console.log("Error: ", er);
  }
}

function* deleteProductOfferApiCall({ payload }) {
  let { callback } = payload;

  yield put(
    setLoader({
      key: "react_table_loader",
      data: true,
    })
  );

  try {
    yield call(makeRequest, {
      method: "DELETE",
      endpoint: `/partner/deleteProductOffer/${payload.id}`,
      apiBody: {},
    });

    yield put(fetchProductOffers());

    yield put(
      setLoader({
        key: "react_table_loader",
        data: false,
      })
    );

    callback();
  } catch (er) {
    console.log("Error: ", er);
  }
}

export function* GeneralSaga() {
  yield takeEvery(FETCH_COUNTRY_DATA, getCountriesData);
  yield takeEvery(FETCH_STATE_DATA, getStateData);
  yield takeEvery(FETCH_CITIES_DATA, getCitiesData);
  yield takeEvery(TYPES.FETCH_USER_DATA, getUserData);
  yield takeEvery(GENERAL_API_CALL, generalApiCall);
  yield takeEvery(TYPES.ADD_CUSTOMER, addCustomerApiCall);
  yield takeEvery(TYPES.ADD_POS_ORDER, addPOSOrderApiCall);
  yield takeEvery(TYPES.UPDATE_WEBSITE_META, updateWebsiteMetaApiCall);
  yield takeEvery(TYPES.UPDATE_PRODUCT_DATA, updateProductDataApiCall);
  yield takeEvery(TYPES.DELETE_OFFERED_PRODUCT, deleteProductOfferApiCall);
  yield takeEvery(TYPES.FETCH_USERS_LIST, fetchUserListApiCall);
  yield takeEvery(TYPES.FETCH_INVENTORY_DATA, fetchInventoryApiCall);
  yield takeEvery(TYPES.FETCH_SUPPLIERS_DATA, fetchSuppliersApiCall);
  yield takeEvery(TYPES.FETCH_ORDERS_DATA, fetchOrdersApiCall);
  yield takeEvery(TYPES.UPDATE_CUSTOMER, updateCustomerApiCall);
  yield takeEvery(TYPES.GET_FORM_STRUCTURE, getFormStructureApiCall);
  yield takeEvery(TYPES.FETCH_SERVICE_BILLS, getServiceBillsApiCall);
  yield takeEvery(TYPES.GET_CUSTOMER_BY_ID, getCustomerById);
  yield takeEvery(TYPES.FETCH_CATEGORY_LIST, getCategoryListApiCall);
  yield takeEvery(TYPES.ADD_PRODUCT_OFFER, addProductOfferApiCall);
  yield takeEvery(TYPES.UPDATE_PRODUCT_OFFER, updateProductOfferApiCall);
  yield takeEvery(
    TYPES.UPDATE_SERVICE_BILL_TRANSACTION,
    updateServiceBillTransactionApiCall
  );
  yield takeEvery(
    UPDATE_BUSINESS_CONFIGURATION,
    updateBusinessConfigurationsData
  );
  yield takeEvery(TYPES.DELETE_POS_BILL, deletePOSBillApiCall);
  yield takeEvery(
    TYPES.UPDATE_POS_ORDER_PAYMENT_STATUS,
    updatePOSOrderBillStatus
  );
  yield takeEvery(TYPES.UPDATE_CONFIGURATIONS, updateConfigurationsApiCall);
  yield takeEvery(TYPES.GET_ORDER_BY_ID, getOrderByIdApiCall);
  yield takeLatest(TYPES.GET_ONLINE_ORDER_BY_ID, getOnlineOrderById);
  yield takeLatest(TYPES.FETCH_SLOTS, fetchSlotsApiCall);
  yield takeLatest(TYPES.FETCH_COLLECTED_ORDERS, fetchCollectedOrders);
  yield takeLatest(TYPES.FETCH_RETURNED_ORDERS, fetchReturnedOrders);
  yield takeLatest(TYPES.FETCH_ALL_USERS_DATA, fetchUsersList);
  yield takeLatest(TYPES.IMPORT_PRODUCTS, importProductsApiCall);
  yield takeLatest(TYPES.FETCH_PRODUCTS, fetchProductsApiCall);
  yield takeLatest(TYPES.FETCH_PRODUCTS_LIST, fetchProductsListApiCall);
  yield takeLatest(
    TYPES.FETCH_PRODUCTS_PROGRESSIVELY,
    fetchProductsProgressivelyListApiCall
  );
  yield takeLatest(TYPES.FETCH_PURCHASE_DATA, fetchPurchaseDataApiCall);
  yield takeLatest(
    TYPES.FETCH_PRODUCT_OFFERS_DATA,
    fetchProductOffersDataApiCall
  );
  yield takeLatest(TYPES.FETCH_POS_DATA, fetchPOSDataApiCall);
  yield takeLatest(TYPES.CREATE_SLOTS, createSlotApiCall);
  yield takeLatest(TYPES.UPDATE_SLOTS, updateSlot);
}
