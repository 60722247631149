import { httpClient } from "../config/httpClient";
import Resizer from "react-image-file-resizer";
import _ from "lodash";
import imageCompression from "browser-image-compression";
import Decimal from "decimal.js";

export const S3_ASSETS_PATH = (key, userData) => {
  let paths = {
    meta: `${userData.business_id}/meta`,
    category: `${userData.business_id}/category`,
    product: `${userData.business_id}/product`,
    profile: "business_profile",
  };

  return paths[key];
};

export const fileupload = ({ file, folder, disk = "public" }) => {
  let fd = new FormData();
  fd.append("file", file);
  fd.append("folder", folder);
  fd.append("disk", disk);

  return new Promise((resolve, reject) => {
    httpClient("POST", "/common/uploadFile", {
      body: fd,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const validateHeightWidth = ({ file, height, width }) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width === width && img.height === height) {
        resolve(true);
      } else {
        reject(`Sorry, this image doesn't look like the size we wanted. It's 
        ${img.width} x ${img.height} but we require 100 x 100 size image.`);
      }
    };
  });
};

export const resizeImageDimensions = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export function convertBackendErrorResponse(response) {
  const convertedResponse = {};
  for (const key in response) {
    if (response.hasOwnProperty(key)) {
      const value = response[key];
      if (Array.isArray(value)) {
        convertedResponse[key] = value.join(" ");
      } else {
        convertedResponse[key] = value;
      }
    }
  }
  return convertedResponse;
}

export function truncateUpTo(value, decimals = 2) {
  // Ensure the input is treated as a number
  const num = parseFloat(value);
  if (isNaN(num)) {
    throw new Error("Invalid number input");
  }
  // Calculate the factor to shift the decimal point
  const factor = Math.pow(10, decimals);
  // Truncate the number by removing fractional digits beyond the specified decimals
  const truncated = Math.trunc(num * factor) / factor;
  // Return the truncated number
  return truncated;
}

export const calculateRoundOFF = (_maxValue, minValue) => {
  const result = new Decimal(_maxValue).minus(minValue);
  return result.toFixed(2);
};

export function currency({ currency = "INR", value, symbol = true }) {
  let options = {
    // maximumSignificantDigits: 6,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency,
  };

  if (symbol) {
    options.style = "currency";
  }

  return new Intl.NumberFormat("en-US", options).format(value);
}

export function currencyConverationForPOS({ currency = "INR", value }) {
  return new Intl.NumberFormat("en-US", {
    // maximumSignificantDigits: 6,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency,
  }).format(value);
}

export function firstLetterUpperCase(value) {
  return _.startCase(_.toLower(value));
}

export function getLocalStorageData(key) {
  return localStorage.getItem(key);
}

export function setLocalStorageData(key, value) {
  return localStorage.setItem(key, value);
}

export const compressImage = async (imageFile) => {
  // const imageFile = event.target.files[0];
  console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
  console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    console.log(
      "compressedFile instanceof Blob",
      compressedFile instanceof Blob
    ); // true
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};
