import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Composition from "./Composition";
import MiniBillFormat from "./MiniBillFormat";
import { getOrderById, getOnlineOrderById } from "../../store/actions";
import { selectOrderData } from "../../store/selectors";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import MiniBillEcommerceFormat from "./MiniBillEcommerceFormat";
import {
  Box,
  Button,
  DialogContentText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useFormik } from "formik";
import Modal from "../../MuiComponent/Modal";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useGeneralStyle from "../../assets/css/general";
import TextField from "../../MuiComponent/TextField";
import { httpClient } from "../../config/httpClient";
const MiniBillTaxableFormat = React.lazy(() => import("./MiniBillTaxableFormat"));
const Invoice = React.lazy(() => import("./Invoice"));

export function InvoicePreviewFromPOS() {
  const { order_id } = useParams();
  const billRef = useRef(null);
  const location = useLocation();
  const { userData } = useSelector((state) => state.general);
  const orderDetail = useSelector(selectOrderData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOrderById({
        order_id,
        callback: () => {
          if (location.state?.printout) {
            setTimeout(() => {
              billRef.current.handlePrint();
            }, 500);
          }
        },
      })
    );
  }, []);

  return (
    <>
      {userData?.businessDetails?.business_configurations?.billing_format ==
        "detailed" ? (
        <>
          {orderDetail.tax_scheme == "regular" ? (
            <>
              {/* Regular Scheme Detailed Preview  */}
              <Invoice ref={billRef} data={orderDetail} onBack={() => history.back()} />
            </>
          ) : (
            <>
              {/* Composition Scheme Detailed Preview  */}
              <Composition
                ref={billRef}
                data={orderDetail}
                onBack={() => history.back()}
              />
            </>
          )}
        </>
      ) : orderDetail.tax_scheme == "regular" ? (
        <>
          {/* Regular Scheme Compact Preview */}
          <MiniBillTaxableFormat
            data={orderDetail}
            onBack={() => history.back()}
          />
        </>
      ) : (
        <>
          {/* Composition Scheme Compact Preview */}
          <MiniBillFormat
            ref={billRef}
            data={orderDetail}
            onBack={() => history.back()}
          />
        </>
      )}
    </>
  );
}

export function InvoicePreviewOrderManagement() {
  const { order_id } = useParams();
  const billRef = useRef(null);
  const fetchApiStatus = useCallbackStatus();
  const location = useLocation();
  const globalClasses = useGeneralStyle();
  const [modal, setModal] = React.useState(false);
  const { userData } = useSelector((state) => state.general);
  const updateApiStatus = useCallbackStatus();
  const orderDetail = useSelector(selectOrderData);
  const dispatch = useDispatch();
  const [deliveryStaff, setDeliveryStaff] = useState([]);

  const getDeliveryStaff = async () => {
    await fetchApiStatus.run(
      httpClient("POST", `/partner/getUsers`, {
        body: {
          user_type: "delivery_staff",
        },
      }).then((result) => {
        const {
          data: { data },
        } = result;

        setDeliveryStaff(data);
      })
    );
  };

  useEffect(() => {
    getDeliveryStaff();
  }, []);

  useEffect(() => {
    dispatch(
      getOnlineOrderById({
        order_id,
        callback: () => {
          if (location.state?.printout) {
            billRef.current.handlePrint();
          }
        },
      })
    );
  }, []);

  const updateStatus = async () => {
    await updateApiStatus.run(
      httpClient("POST", "/partner/updatedStatus", {
        body: {
          status: orderStatusForm.values.status,
          id: order_id,
          tracking_id: orderStatusForm.values.tracking_id,
          delivery_boy_id: orderStatusForm.values.delivery_boy_id,
        },
      })
        .then((result) => {
          console.log("Order", result);
          setModal(false);
          onBack();
        })
        .catch((er) => {
          console.log("ERROR ", er);
        })
    );
  };

  const orderStatusForm = useFormik({
    initialValues: {
      status: "placed",
      tracking_id: "",
      delivery_boy_id: "",
      reason: "",
    },
    onSubmit: () => {
      updateStatus();
    },
  });

  return (
    <>
      <Modal
        title="Update Status"
        height="300px"
        maxWidth="md"
        width="45%"
        closeBtnText="close"
        saveBtnText="save"
        saveHandler={orderStatusForm.submitForm}
        loading={updateApiStatus.isPending}
        closeHandler={() => {
          setModal(!modal);
        }}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Status
                </InputLabel>
                <Select
                  value={orderStatusForm.values.status}
                  variant="outlined"
                  fullWidth
                  onChange={(event) => {
                    orderStatusForm.setFieldValue("status", event.target.value);
                  }}
                >
                  <MenuItem disabled value="placed">
                    Placed
                  </MenuItem>
                  <MenuItem value="shipped">Shipped</MenuItem>
                  <MenuItem value="delivered">Delivered</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </Box>
              <Box>
                {orderStatusForm.values.status == "shipped" && (
                  <>
                    <InputLabel className={globalClasses.inputLabel}>
                      Delivery Boy
                    </InputLabel>
                    <Select
                      name="delivery_boy_id"
                      id="delivery_boy_id"
                      value={orderStatusForm.values.delivery_boy_id}
                      variant="outlined"
                      fullWidth
                      onChange={(event) => {
                        orderStatusForm.setFieldValue(
                          "delivery_boy_id",
                          event.target.value
                        );
                      }}
                    >
                      <MenuItem disabled value="">
                        Select
                      </MenuItem>
                      {deliveryStaff.map((deliveryBoy) => {
                        return (
                          <MenuItem value={deliveryBoy.id}>
                            {deliveryBoy.name} {deliveryBoy.last_name} { }
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <InputLabel className={globalClasses.inputLabel}>
                      Traking Id
                    </InputLabel>

                    <TextField
                      name="tracking_id"
                      placeholder="Traking Id"
                      error={
                        orderStatusForm.touched.tracking_id &&
                        orderStatusForm.errors.tracking_id
                      }
                      helperText={
                        orderStatusForm.touched.tracking_id &&
                        orderStatusForm.errors.tracking_id
                      }
                      onChange={orderStatusForm.handleChange}
                      onBlur={orderStatusForm.handleBlur}
                      value={orderStatusForm.values.tracking_id}
                      variant="outlined"
                    />
                  </>
                )}
                {orderStatusForm.values.status == "cancelled" && (
                  <>
                    <InputLabel className={globalClasses.inputLabel}>
                      Reason
                    </InputLabel>
                    <TextField
                      name="reason"
                      placeholder="Reason"
                      // error={touched.note && errors.note}
                      // helperText={touched.note && errors.note}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // value={values.note}
                      // disabled={!isEditMode}
                      multiline
                      variant="outlined"
                    />
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      {userData?.businessDetails?.business_configurations?.billing_format ==
        "detailed" ? (
        <>
          {/* {orderDetail.tax_scheme == "regular" ? ( */}
          {true ? (
            <>
              {/* Regular Scheme Detailed Preview  */}
              <Invoice
                data={orderDetail}
                onBack={() => history.back()}
                updateStatus={() => {
                  setModal(!modal);
                }}
              />
            </>
          ) : (
            <>
              {/* Composition Scheme Detailed Preview  */}
              <Composition data={orderDetail} onBack={() => history.back()} />
            </>
          )}
        </>
      ) : true ? (
        <>
          {/* Regular Scheme Compact Preview */}
          <MiniBillTaxableFormat
            data={orderDetail}
            onBack={() => history.back()}
            updateStatus={() => {
              setModal(!modal);
            }}
          />
        </>
      ) : (
        <>
          {/* Composition Scheme Compact Preview */}
          <MiniBillEcommerceFormat
            ref={billRef}
            data={orderDetail}
            onBack={() => history.back()}
          />
        </>
      )}
    </>
  );
}