import { httpClient } from "../config/httpClient";

export const fetchData = (payload) => {
  let { method, endpoint, apiBody } = payload;

  return httpClient(method, endpoint, {
    body: apiBody,
  })
    .then((result) => {
      return result;
    })
    .catch((er) => {
      throw er;
    });
};

export const makeRequest = (payload) => {
  let { method, endpoint, apiBody, ...configs } = payload;

  return httpClient(method, endpoint, {
    body: apiBody,
    ...configs
  })
    .then((result) => {
      return result;
    })
    .catch((er) => {
      throw er;
    });
};
