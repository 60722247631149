import { createAction } from "@reduxjs/toolkit";
import {
  FETCH_CITIES_DATA,
  FETCH_COUNTRY_DATA,
  FETCH_STATE_DATA,
  GENERAL_API_CALL_DATA,
  SET_REDUCER_DATA,
  SET_USER,
  TYPES,
  UPDATE_BUSINESS_CONFIGURATION,
} from "../constants";
import { GENERAL_API_CALL } from "../constants";

export const setUser = (payload) => {
  return {
    type: SET_USER,
    payload,
  };
};

export const fetchCountries = createAction(FETCH_COUNTRY_DATA);
export const fetchCities = createAction(FETCH_CITIES_DATA);
export const createSlot = createAction(TYPES.CREATE_SLOTS);
export const fetchSlots = createAction(TYPES.FETCH_SLOTS);
export const fetchCollectedOrders = createAction(TYPES.FETCH_COLLECTED_ORDERS);
export const fetchReturnedOrders = createAction(TYPES.FETCH_RETURNED_ORDERS);
export const fetchAllUsersData = createAction(TYPES.FETCH_ALL_USERS_DATA);
export const setCollectedOrders = createAction(TYPES.SET_COLLECTED_ORDERS);
export const setReturnedOrders = createAction(TYPES.SET_RETURNED_ORDERS);
export const setUsersList = createAction(TYPES.SET_DATATABLE_USER_LIST);
export const importProducts = createAction(TYPES.IMPORT_PRODUCTS);
export const fetchProductsProgressively = createAction(
  TYPES.FETCH_PRODUCTS_PROGRESSIVELY
);
export const fetchProducts = createAction(TYPES.FETCH_PRODUCTS);
export const fetchProductsList = createAction(TYPES.FETCH_PRODUCTS_LIST);
export const fetchPurchaseData = createAction(TYPES.FETCH_PURCHASE_DATA);
export const fetchProductOffers = createAction(TYPES.FETCH_PRODUCT_OFFERS_DATA);
export const setProductOffersData = createAction(TYPES.SET_PRODUCT_OFFERS_DATA);
export const addProductOffer = createAction(TYPES.ADD_PRODUCT_OFFER);
export const updateProductOffer = createAction(TYPES.UPDATE_PRODUCT_OFFER);
export const fetchPOSData = createAction(TYPES.FETCH_POS_DATA);
export const setProductsList = createAction(TYPES.SET_PRODUCTS_LIST);
export const setSlotsData = createAction(TYPES.SET_SLOTS);
export const updateSlot = createAction(TYPES.UPDATE_SLOTS);
export const fetchStates = createAction(FETCH_STATE_DATA);
export const setReducerData = createAction(SET_REDUCER_DATA);
export const generalApiCall = createAction(GENERAL_API_CALL);
export const setGeneralApiData = createAction(GENERAL_API_CALL_DATA);
export const fetchUser = createAction(TYPES.FETCH_USER_DATA);
export const updateBusinessConfigurations = createAction(
  UPDATE_BUSINESS_CONFIGURATION
);

export const getFormStructure = createAction(TYPES.GET_FORM_STRUCTURE);
export const setFormStructure = createAction(TYPES.SET_FORM_STRUCTURE);
export const setUserList = createAction(TYPES.SET_USER_LIST);
export const setFormValidations = createAction(TYPES.SET_FORM_VALIDATIONS);

export const fetchCategories = createAction(TYPES.FETCH_CATEGORY_LIST);

export const addCustomer = createAction(TYPES.ADD_CUSTOMER);
export const fetchUserList = createAction(TYPES.FETCH_USERS_LIST);
export const updateCustomer = createAction(TYPES.UPDATE_CUSTOMER);

export const updateConfigurations = createAction(TYPES.UPDATE_CONFIGURATIONS);
export const setConfigurations = createAction(TYPES.SET_CONFIGURATIONS);

export const syncCacheData = createAction(TYPES.SYNC_CACHE_DATA);

export const setLoader = createAction(TYPES.SET_LOADER);
export const setPOSData = createAction(TYPES.SET_POS_DATA);

export const fetchInventory = createAction(TYPES.FETCH_INVENTORY_DATA);
export const setInventoryData = createAction(TYPES.SET_INVENTORY_DATA);

export const fetchSuppliers = createAction(TYPES.FETCH_SUPPLIERS_DATA);
export const setSuppliersData = createAction(TYPES.SET_SUPPLIERS_DATA);

export const fetchOrders = createAction(TYPES.FETCH_ORDERS_DATA);
export const setOrdersData = createAction(TYPES.SET_ORDERS_DATA);
