const { makeStyles } = require("@material-ui/core");

const useGeneralStyle = makeStyles((theme) => ({
  otp_input:{
    margin:'10px',
    padding:"10px",
    height:'60px',
    width:'60px !important',
    fontSize:"30px",
    [theme.breakpoints.down("sm")]: {
      height:'40px',
      width:'40px !important',
    }
  },
  barColorPrimary:{
    backgroundColor:"#fbc068"
  },
  progressBarRoot:{
    backgroundColor:"#e0e0e0",
    height:"40px"
  },
  otp_input_popup:{
    margin:'10px',
    padding:"10px",
    height:'30px',
    width:'30px',
    fontSize:"30px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      fontSize: "20px",
      width: "10px",
      height: "10px",
    }
  },
  otp_input_registration_popup:{
    margin:'10px',
    padding:"10px",
    height:'52px',
    width:'52px !important',
    fontSize:"30px",
    [theme.breakpoints.down("sm")]: {
      fontSize:"15px",
      height:'32px',
      width:'32px !important',
    }
  },
  inputLabel: {
    color: "black",
    fontWeight: 600,
    fontSize: "13px",
    padding: "10px 0px",
  },
  filterLabel: {
    color: "black",
    fontWeight: 600,
    fontSize: "13px",
  },
  link: {
    fontWeight: 600,
    cursor: "pointer",
    textDecoration: "none",
    color: "#db2116",
  },
  selectInput: {
    backgroundColor: "white",
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
  datatablePaper: {
    width: "165vh",
    overflowX: "auto",
    backgroundColor: "white",
    padding: "10px",
  },
}));

export const styles = {
  reportTable: {
    border: "2px solid #707070",
    borderCollapse: "collapse",
    width: "100%",
    color: "#6c6c6c",
  },
  reportTr: { borderBottom: "2px solid #707070" },
  reportTh: { borderRight: "2px solid #707070" },
};

export default useGeneralStyle;
