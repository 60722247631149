import { createAction } from "@reduxjs/toolkit";
import { TYPES } from "./constants";

export * from "./category/actions";
export * from "./user/actions";

export const getCustomerById = createAction(TYPES.GET_CUSTOMER_BY_ID);
export const setCustomerById = createAction(TYPES.SET_CUSTOMER_BY_ID);

export const getServiceBills = createAction(TYPES.FETCH_SERVICE_BILLS);
export const setServiceBills = createAction(TYPES.SET_SERVICE_BILLS);

export const updateServiceBillTransaction = createAction(
  TYPES.UPDATE_SERVICE_BILL_TRANSACTION
);

export const addPOSOrder = createAction(TYPES.ADD_POS_ORDER);
export const getOrderById = createAction(TYPES.GET_ORDER_BY_ID);
export const getOnlineOrderById = createAction(TYPES.GET_ONLINE_ORDER_BY_ID);
export const setOrderData = createAction(TYPES.SET_ORDER_BY_ID);
export const setProductsData = createAction(TYPES.SET_PRODUCTS_DATA);
export const deletePOSBill = createAction(TYPES.DELETE_POS_BILL);
export const updatePaymentStatus = createAction(TYPES.UPDATE_POS_ORDER_PAYMENT_STATUS);
export const setPurchaseData = createAction(TYPES.SET_PURCHASE_DATA);
export const deleteOfferProduct = createAction(TYPES.DELETE_OFFERED_PRODUCT);
export const updateWebsiteMeta = createAction(TYPES.UPDATE_WEBSITE_META);
export const updateProductData = createAction(TYPES.UPDATE_PRODUCT_DATA);

